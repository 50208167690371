export function translate(message) {
    switch (message) {
        case 'auth/user-not-found':
            return 'Nie znaleziono użytkownika.';
        case 'auth/wrong-password':
            return 'Podane hasło jest nieprawidłowe.';
        case 'auth/invalid-action-code':
            return 'Token został już wykorzystany bądź stracił ważność.';
        default:
            return 'Missing translate: ' + message;
    }
}