import React from 'react';
import Helmet from 'react-helmet';
import GlobalStyle from 'assets/styles/GlobalStyle';
import useSiteMetadata from 'components/SiteMetadata';
import 'assets/styles/normalize.css';
import 'assets/styles/firebaseui-styling.global.css';

const MainTemplate = ({ children }) => {
    const { title, description, image } = useSiteMetadata();
    return (
        <>
            <Helmet>
                <html lang="pl" />
                <title>{title}</title>
                <meta property="description" content={description} />

                <meta property="twitter:card" content={title} />
                <meta property="twitter:description" content={description} />
                <meta property="twitter:image" content={image} />

                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:url" content={process.env.SITE_URL} />
                <meta property="og:image" content={image} />

                <link href="https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap" rel="stylesheet"/>
            </Helmet>

            <GlobalStyle />
            {children}
        </>
    );
};

export default MainTemplate;
