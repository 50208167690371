import React from "react"
import styled from "styled-components"
import theme from "assets/styles/theme"
import PropTypes from "prop-types"

const StyledButton = styled.button`
    display: block;
    position: relative;
    width: 170px;
    padding: 0.5em 1em;
    text-align: center;
    line-height: 1.5em;
    color: white;
    border-radius: 30px;
    transition: all 0.2s;
    background: ${theme.color.mainBlueColor};
    border: none;
    font-size: 1.4rem;
    margin: 2rem auto 0;

    &:focus {
        outline: none;
    }

    &:disabled {
        opacity: 0.5;
    }
`

const Button = props => {
    const handleClick = () => {
        if (props.onClick) {
            props.onClick()
        }
    }

    return <StyledButton type={props.type} className={props.className} onClick={handleClick}
                         disabled={props.disabled}>{props.title}{props.children}</StyledButton>
}

Button.defaultProps = {
    title: ``,
    type: ``,
}

Button.propTypes = {
    title: PropTypes.string,
    type: PropTypes.string,
}

export default Button
