import React from 'react';
import styled from "styled-components";
import InputMask from "react-input-mask"
import theme from "assets/styles/theme";

const TextInputWrapper = styled.div`
    text-align: center;
    margin-bottom: 1rem;
    padding: ${({padding}) => (padding ? padding : '0 24px')};
    
    span {
        margin-top: 1rem;
    }
`;

const StyledInput = styled(InputMask)`
    position: relative;
    width: 100%;
    border-radius: ${theme.radius.input};
    border: 1px solid ${theme.color.inputBorderColor};
    padding: ${theme.padding.input};
    background: none transparent !important;
    color: white;
    text-align: center;
    margin-bottom: 0.5rem;
    
    &::placeholder {
      font-size: ${({placeholdersmaller}) => placeholdersmaller ? "80%" : "100%"};
    }
    
    &[type="date"] { 
        -webkit-appearance: none;
    
        &:before {
            content: ${({date, isrequired}) => (date ? date : (isrequired === 'true' ? "'Data urodzenia'" : "'Data urodzenia *'"))};
            text-align: center;
            width: 100%;
            color: ${theme.color.placeholderColor};
            margin-left: 1rem;
        }
        
        &::-webkit-calendar-picker-indicator {
            display: none;
            -webkit-appearance: none;
        }
    }

    &:focus {
      outline: none;
      
      &:before {
            content: none;
        }
    }
`;

const SimpleStyledInput = styled(StyledInput)`
    text-align: left;
`;

const TextInput = ({handler, touched, hasError, meta}) => {
    return (
        <TextInputWrapper padding={meta.padding}>
            {meta.style === 'bordered' &&
                <div>
                    <StyledInput
                        type={meta.type ? meta.type : ''}
                        placeholder={meta.required ? meta.label + " *" : meta.label}
                        hidden={meta.hidden}
                        date={handler().value}
                        mask={meta.mask}
                        isrequired={meta.required}
                        placeholdersmaller={meta.placeholdersmaller}
                        {...handler()}
                    />
                </div>
            }
            {meta.style === 'simple' &&
                <div>
                    <SimpleStyledInput
                        type={meta.type ? meta.type : ''}
                        placeholder={meta.required ? meta.label + " *" : meta.label}
                        hidden={meta.hidden}
                        date={handler().value}
                        mask={meta.mask}
                        isrequired={meta.required}
                        {...handler()}
                    />
                </div>
            }

            <span>
                {touched && hasError && meta.message && meta.message}
                {touched
                && hasError("required")
                && `Pole ${meta.label} jest wymagane.`}

                {touched
                && hasError("email")
                && `${meta.label} nie jest poprawny.`}

                {touched
                && hasError("minLength")
                && `Minimalna długość ${meta.label} wynosi ${meta.minLength} znaków.`}
            </span>
        </TextInputWrapper>
    );
};

export default TextInput;