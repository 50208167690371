import React from 'react';
import styled from 'styled-components';
import 'assets/styles/loaders.min.css';
import theme from 'assets/styles/theme';

const StyledLoader = styled.div`
    display: flex;
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.9);
    top: 0;
    left: 0;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    
    .ball-scale-multiple > div {
        background-color: ${theme.color.mainBlueColor};
        left: -70px;
        top: -70px;
        width: 150px;
        height: 150px;
    }
`;

const Loader = props => {
    return (
        <StyledLoader>
            <div className="loader">
                <div className="ball-scale-multiple">
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </StyledLoader>
    );
};

export default Loader;
