import { createGlobalStyle } from "styled-components"
import theme from "./theme"

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent; /* for removing the highlight */
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-size: 1.6rem;
    font-family: ${theme.font.family.main};
    font-weight: 300;

    color: white;
    background-color: ${theme.color.backgroundColor};
    backface-visibility: hidden;
  }

  a {
    text-decoration: none;
  }

  a:hover, a:focus {
    text-decoration: none;
  }

  .container {
    margin-left: auto;
    margin-right: auto;
    max-width: 1100px; 
    padding-right: 15px;
    padding-left: 15px;
    position: relative;
  }

  ::placeholder {
    color: rgba(255,255,255, 0.7);
    opacity: 0.7; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: rgba(255,255,255, 0.7);
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: rgba(255,255,255, 0.7);
  }
  
  summary::-webkit-details-marker {
    display:none;
  }

  /* Change Autocomplete styles in Chrome*/
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    transition: background-color 9999s ease-in-out 0s;
    -webkit-text-fill-color: #fff !important;
  }

  .MuiSnackbar-root {
    span {
      font-size: 1.5em !important;
    }

    &.success {
      background-color: green;
    }

    &.error {
      background-color: red;
    }

    .MuiSnackbarContent-root {
      box-shadow: 0 0 0 0 transparent;
      background-color: inherit;
    }
  }

    .error {
    text-align: center;
    font-weight: bold;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
`

export default GlobalStyle
