import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import theme from 'assets/styles/theme';

const Header = styled.div`
  font-size: 3.5rem;
  font-weight: bold;
  margin-top: 3rem;
  margin-bottom: 1rem;
  text-align: ${({center}) => (center ? 'center' : 'left')};
  position: relative;
  padding: ${({padding}) => (padding ? theme.padding.layout : '0')};
`;

const SectionHeader = props => {
    return <Header center={props.center} padding={props.padding}>{props.header}</Header>;
};

SectionHeader.defaultProps = {
    header: `Nagłówek`
};

SectionHeader.propTypes = {
    header: PropTypes.string,
    center: PropTypes.string,
};

export default SectionHeader;
