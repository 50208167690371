const theme = {
  font: {
    family: {
      main: 'Montserrat, Arial, sans-serif',
    },
    size: {
      xs: '1.4rem',
      s: '2.6rem',
      m: '4.2rem',
      l: '5rem',
      xl: '7.2rem',
      xxl: '9.4rem',
    },
    weight: {
      light: '300',
      regular: '400',
      medium: '500',
      bold: '700',
    },
  },
  color: {
    backgroundColor: '#07050c',
    mainBlueColor: '#0030ff',
    secondaryBlueColor: '#E5EAFF',
    green: '#84C658',
    darkGreen: '#152E0E',
    red: '#CE292F',
    darkRed: '#4E020E',
    placeholderColor: 'rgba(255,255,255, 0.7)',
    inputBorderColor: '#ffffff',
  },
  mq: {
    small: `@media (max-width: 1024px)`,
    medium: `@media (max-width: 1200px)`,
  },
  padding: {
    layout: '15px',
    input: '0.8em 0.4em',
  },
  radius: {
    input: '0.5em'
  },
};

export default theme;